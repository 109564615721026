<template>
  <div >
    <el-row>

      <el-button style="float: left" size="mini" type="primary" icon="el-icon-plus" @click="showParkingSpaceBinding()">新增車位綁定資訊</el-button>
      <div style="float: right;">
        <!--        <el-select v-model="listQuery.serviceId" clearable size="small" v-bind:placeholder="$t('AllOperator')" class="filter-item-mid" v-if="serviceList.length>0">
          <el-option v-for="item in serviceList" :key="item.id" :label="item.name + ' (' + item.code + ')'" :value="item.id" />
        </el-select>-->

        <el-select v-model="listQuery.stationId" :placeholder="$t('All')+' '+$t('ChargingStation')" size="small" class="filter-item" clearable>
          <el-option
                v-for="item in this.chargingStations"
                :label="item.stationName"
                :key="item.stationId"
                :value="item.stationId">
          </el-option>
        </el-select>
        <!--el-select v-model="listQuery.charge_point_id" :placeholder="$t('All')+' '+$t('ChargingPoint')" size="small" class="filter-item" clearable>
          <el-option
                v-for="item in this.chargingPoints"
                :label="item.pointName"
                :key="item.pointId"
                :value="item.pointId">
          </el-option>
        </el-select-->
        <!--
        <el-input size="small" v-model="listQuery.description" v-bind:placeholder="$t('Name')" class="filter-item" clearable />
      -->
      </div>
    </el-row>

    <el-table
      :header-cell-style="{color:'#20638C'}"
      v-loading="listLoading"
      :data="list" style="width: 100%;"
      :cell-style="{padding: '0', height: '40px'}"
      @sort-change="tableSortChange"
     >
      <el-table-column align="center" prop="data_id"   label="編號" min-width="50%"  />
      <el-table-column align="center" prop="s_name"   :label="$t('Operator')" min-width="80%"  />
      <el-table-column align="center" prop="cs_name"        :label="$t('ChargingStation')" min-width="80%"  />
      <el-table-column align="center" prop="cp_name"         label="充電樁"  />
      <el-table-column align="center" prop="connector_id"       label="槍編號" />
      <el-table-column align="center" prop="description"    label="車位資訊"  />
      <el-table-column align="center" prop="parking_typeS"  label="車位類型" />
      <el-table-column  align="center" prop="floorS"   :label="$t('Floor')"  min-width="60%"/>
      <el-table-column align="center" :label="$t('Operate')" width="130px" >
          <template slot-scope="scope">
             <el-button type="success" :title="$t('Edit')" size="mini" icon="el-icon-setting" @click="showParkingSpaceBindingUpdate(scope.row)"/>
             <el-button type="danger" :title="$t('Delete')" size="mini" icon="el-icon-delete" @click="handleParkingSpaceBindingDelete(scope.$index, scope.row)" />
          </template>
      </el-table-column>
    </el-table>
    <!--分頁組件-->
    <pagination v-show="total>0" :total="total" :current.sync="paginationSelectedPage" :size.sync="listQuery.size" @pagination="getList" />
   <el-dialog width="700px"  :visible.sync="dialogParkingSpaceBinding" @close="dialogOnClose()" >

<el-row align="left" style="font-size:18px"  >新增車位綁定資訊  </el-row>

   <el-divider content-position="left" style="font-size:16px">充電樁資訊</el-divider>
   <el-row><el-col align="right" :span="12">充電站&emsp;
   <el-select v-model="listQuery1.stationId" :placeholder="$t('ChargingStationS')+'('+$t('All')+')'"
           clearable size="small" width="140px" align="left">
           <!--
          <el-option v-for="item in stationOption" :key="item.stationId"
                    :label="item.stationName + ' (id:' + item.stationId + ')'" :value="item.stationId" />-->
          <el-option v-for="item in stationOption" :key="item.stationId"
                    :label="item.stationName" :value="item.stationId" />
        </el-select></el-col>
        &emsp;車位資訊&emsp;
   <el-select v-model="listQuery1.parkingID" placeholder="車位資訊"
           clearable size="small" width="140px" align="left">
           <!--
          <el-option v-for="item in stationOption" :key="item.stationId"
                    :label="item.stationName + ' (id:' + item.stationId + ')'" :value="item.stationId" />-->
          <el-option v-for="item in parkingspaceOption" :key="item.id"
                    :label="item.description" :value="item.id" />
        </el-select>
  </el-row>
  <br>
  <el-row align="left"> <el-col align="right" :span="12">充電樁&emsp;
        <el-select v-model="listQuery1.chargePointId" :placeholder="$t('ChargingPointS')+'('+$t('All')+')'"
           clearable size="small" width="140px" >

          <el-option v-for="item in chargeOption"  :key="item.pointId"
                    :label="item.pointName" :value="item.pointId" />
        </el-select></el-col>
      </el-row>
      <br>
      <el-row align="left"><el-col align="right" :span="12">充電槍&emsp;
        <el-select v-model="listQuery1.connectorId" :placeholder="$t('ChargingConnectorS')+'('+$t('All')+')'"
           clearable size="small"  width="140px" >
           <!--
          <el-option v-for="item in connectorOption"  :key="item.connectorId"
                    :label="item.connectorName + ' (id:' + item.connectorId + ')'" :value="item.connectorId" />-->
          <el-option v-for="item in connectorOption"  :key="item.connectorId"
                    :label="item.connectorName" :value="item.connectorId" />
        </el-select></el-col>
      </el-row>      
    <br>
          <p  style="font:20px" v-if="this.showMsg">{{ this.alertMsg }}  </p>
        <br>
       <div style="text-align: center">
        <el-button size="small" @click="dialogParkingSpaceBinding = false">
          取消
        </el-button>
        <el-button size="small" type="primary" @click="parkingSpaceBinding()">
          確定
        </el-button>
        </div>
      </el-dialog>

      <el-dialog width="700px"  :visible.sync="dialogParkingSpaceBindingUpdate" @close="dialogOnClose()" >

<el-row align="left" style="font-size:18px">修改車位綁定資訊  </el-row>

   <el-divider content-position="left" style="font-size:16px">充電樁資訊</el-divider>
   <el-row v-if = " this.ParkingSpaceData"> <el-col align="right" :span="6"> 充電站:&emsp;</el-col> 
    <el-col align="left" :span="6">{{ this.ParkingSpaceData.s_name }}</el-col>
        &emsp;車位資訊&emsp;
   <el-select v-model="listQuery1.parkingID" placeholder="車位資訊"
           clearable size="small" width="140px" align="left">
           <!--
          <el-option v-for="item in stationOption" :key="item.stationId"
                    :label="item.stationName + ' (id:' + item.stationId + ')'" :value="item.stationId" />-->
          <el-option v-for="item in parkingspaceOption" :key="item.id"
                    :label="item.description" :value="item.id" />
        </el-select>
  </el-row>

  <el-row v-if = " this.ParkingSpaceData"> <el-col align="right" :span="6"> 充電樁:&emsp;</el-col>
    <el-col align="left" :span="6">{{ this.ParkingSpaceData.cp_name }}</el-col>
    <br><br>       
      </el-row>      
      <el-row v-if = " this.ParkingSpaceData"> <el-col align="right" :span="6"> 充電槍:&emsp;</el-col>
        <el-col align="left" :span="6">{{ this.ParkingSpaceData.connector_id }}</el-col>
      </el-row>
      
    <br>
          <p  style="font:20px" v-if="this.showMsg">{{ this.alertMsg }}  </p>
        <br>
       <div style="text-align: center">
        <el-button size="small" @click="dialogParkingSpaceBindingUpdate = false">
          取消
        </el-button>
        <el-button size="small" type="primary" @click="parkingSpaceBindingUpdate()">
          確定
        </el-button>
        </div>
      </el-dialog>
  </div> 
  
</template>

<script>
import { mixins } from '@/views/common/mixins.js';

export default {
  name: 'ParkingSpace',
  components: {
    //Pagination,
    //ParkingSpaceSetting,
  },
  mixins: [mixins],
  data() {
    return {
      result: '',
      dialogWidth: 0,
      total: 0,
      list: [],
      serviceList: [],
      chargingStations: undefined,
      chargingPoints:this.$store.state.chargingPoints,
      listLoading: false,
      listQuery: {
        serviceId: this.$store.state.currentAccount.serviceInfo.id,
        name: undefined,
        stationId: undefined,
        chargePointId: undefined,
        page: 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
        size: 15,
  
      },
      listQuery1: {
                //serviceId: this.$store.state.currentAccount.id,
                serviceId: this.$store.state.currentAccount.serviceInfo.id,
                stationId        : undefined,
                chargePointId    : undefined,
                connectorId: undefined,
                parkingID   : undefined,
                //page: 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
               // size: 15,
                //sort: "update_time,desc",
            },
            updateQuery: {
                dataId      : undefined,
                serviceId   : this.$store.state.currentAccount.serviceInfo.id,
                stationId   : undefined,
                chargePointId : undefined,
                connectorId : undefined,
                parkingID   : undefined,
                //accountValue: this.$store.state.currentAccount.account,
            },
      paginationSelectedPage: 1, // 需配合Pagination元件與listQuery使用
      ParkingSpaceData: undefined, // 停車位資訊
      dialogParkingSpaceBinding: false,
      dialogParkingSpaceBindingUpdate :false,
      stationOption    : undefined,
      chargeOption     : undefined,
      connectorOption  : undefined,
      parkingspaceOption  : undefined,
    };
  },
  watch: {
    paginationSelectedPage: {
      handler: function(newValue) {
        this.listQuery.page = newValue - 1
      }
    },
    //'listQuery.serviceId': function () {
    //    this.listQuery.page = 0
    //    if(this.listQuery.serviceId) this.resetPage(this.getList)
    //    this.chargingStations = (this.$store.state.chargingStations || []).filter(item => item.service_id == this.listQuery.serviceId)
    //    this.listQuery.stationId = undefined
    //},
    'listQuery.stationId': function(val) {
      this.listQuery.page = 0
      this.listQuery.chargePointId = null;
      //this.resetPage(this.getList);
      //this.listAll= [];
      this.chargingPoints=null;
      /* 若充電站有被選擇,則才會找該充電站所屬的充電樁,沒被選擇則stationId為0 */
      if( this.listQuery.stationId != null && this.listQuery.stationId != undefined && this.listQuery.stationId > 0 ) {
        this.axios.get("/api/v1/report/pointidListByserviceIdandstationId?serviceId="+this.listQuery1.serviceId+"&stationId="+val).then(
            res => {
            console.log("Request:pointidListByserviceIdandstationId [res.data]",res.data);
            this.chargingPoints=res.data;
            }
            );
          }
      this.resetPage(this.getList);
    },
    'listQuery.charge_point_id': function() {
      this.listQuery.page = 0
      this.resetPage(this.getList)
    },
    'listQuery1.stationId': function (val) {
      //this.listQuery.page = 0;
      this.listQuery1.chargePointId = null;
      //this.resetPage(this.getList);
      //this.listAll= [];
      this.chargeOption=null;
      /* 若充電站有被選擇,則才會找該充電站所屬的充電樁,沒被選擇則stationId為0 */
      if( this.listQuery1.stationId != null && this.listQuery1.stationId != undefined && this.listQuery1.stationId > 0 ) {
        this.axios.get("/api/v1/report/pointidListByserviceIdandstationId?serviceId="+this.listQuery1.serviceId+"&stationId="+val).then(
            res => {
            console.log("Request:pointidListByserviceIdandstationId [res.data]",res.data);
            this.chargeOption=res.data;
            }
            );
            this.parkingspaceOption=null;
            this.axios.get("/api/v1/parking_binding/query/avaliable_list?serviceId="+this.listQuery1.serviceId+"&stationId="+this.listQuery1.stationId).then(
            res => {
            console.log("parkingspaceOption:",res.data.data);
            this.parkingspaceOption=res.data.data;
            }
            );
      }    
      this.resetPage(this.getList);
     },
    'listQuery1.chargePointId': function (val) {
      //this.listQuery.page = 0;
      this.listQuery1.chargeConnectorId = null;
      //this.resetPage(this.getList);
      //this.listAll= [];
      this.connectorOption=null;
      /* 若充電樁有被選擇,則才會找該充電樁所屬的充電槍,沒被選擇則chargePointId為"" */
      if( this.listQuery1.chargePointId != null && this.listQuery1.chargePointId != undefined && this.listQuery1.chargePointId != "" ) {
        this.axios.get("/api/v1/report/connectoridListBy3Id?serviceId="+this.listQuery1.serviceId+"&stationId="+this.listQuery1.stationId+"&chargepointId="+val).then(
            res => {
            //console.log("Request:connectoridListBy3Id [res.data]",res.data);
            this.connectorOption=res.data;
            this.connectorOption.forEach( item =>{
            item.connectorId = parseInt(item.connectorId )
          })
            }
            );
      }
      this.listQuery1.parkingId = null;
     },
    'listQuery1.chargeConnectorId': function (val) {
      //this.listQuery.page = 0;
      //this.resetPage(this.getList);
      //this.listAll= [];
      //this.connectorOption=null; 
      //this.listQuery1.parkingSpaceId = val;
      //this.parkingspaceOption=null;
      /* 若充電樁有被選擇,則才會找該充電樁所屬的充電槍,沒被選擇則chargePointId為"" */
      //if( this.listQuery1.chargePointId != null && this.listQuery1.chargePointId != undefined && this.listQuery1.chargePointId != "" ) {
       // this.axios.get("/api/v1/parking_binding/query/avaliable_list?serviceId="+this.listQuery1.serviceId+"&stationId="+this.listQuery1.stationId).then(
       //     res => {
        //    console.log("parkingspaceOption:",res.data);
         //   this.parkingspaceOption=res.data;
          //  }
           // );
      //}
     },
     'listQuery1.parkingSpaceId': function (val) {

     },
  },
  created() {
   // this.dialogWidth = this.setDialogWidth() //mixins->用戶寬度高於1300px則取90%,若低於1300則取客戶螢幕寬度
    this.resetPage(this.getList)
   // this.getServiceOptions()
   this.getStationByServiceId();
  },
  beforeDestroy() {
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.dialogWidth = this.setDialogWidth()
      })()
    }
  },
  methods: {
    getStationByServiceId() {
      console.log("GET:/api/v1/report/stationidListByserviceId?serviceId="+this.listQuery1.serviceId);
      this.axios.get("/api/v1/report/stationidListByserviceId?serviceId="+this.listQuery1.serviceId).then(
          res => {
          console.log("Response:/api/v1/report/stationidListByserviceId [res.data]",res.data);
          this.stationOption=res.data;
          this.chargingStations=res.data;
          res.data.forEach( item =>{
            item.stationId = parseInt(item.stationId )
          })
          }
         );
    },
    getList() {
      this.listLoading = true
 
      this.axios.get('/api/v1/parking_binding/query/binding_list', {params: this.listQuery}).then(res=> {
        this.list = res.data.data.content
        this.total = res.data.data.totalElements
        this.listLoading = false
        this.list.forEach( item =>
         {
           if(item.floor==0) item.floorS = "平面層";
           if(item.floor<0 ) item.floorS = "地下第" + Number(Math.abs(item.floor)) + "層";
           if(item.floor>0 ) item.floorS = "立體第" + Number(Math.abs(item.floor)) + "層";       
           console.log(item.floorS);        
           if(item.parking_type == 1){
            item.parking_typeS = "充電車位";
           }else if(item.parking_type == 2){
            item.parking_typeS = "卸貨車位";
           }else if(item.parking_type == 3){
            item.parking_typeS = "一般車位";
           }else{
            item.parking_typeS = "身障車位";
           }            
         }
        )
      })
      this.listLoading = false

    },
    getServiceOptions() {
        this.listLoading = true
        this.axios.get('/api/v1/common/service/enabled').then(res => {
            this.serviceList = res.data //系統管理員才會有serviceList
            if (this.serviceList.length==0) {
                this.listQuery.serviceId = this.$store.state.currentAccount.serviceInfo.id 
                this.chargingStations = (this.$store.state.chargingStations || []).filter(item => item.service_id == this.listQuery.serviceId)
            }
        })
    },
    parkingSpaceBinding() {
     //parking_binding/create/binding
     
     //this.axios.get('/api/v1/parking_binding/create/binding', {params: this.listQuery1}).then(res=> {
     //}
       // )
       this.targetUrl ="/api/v1/parking_binding/create/binding"
        this.axios.post(this.targetUrl, this.listQuery1) .then(response => {
        // 在這裡處理回應
        console.log("response",response.data)
        if (response.data.message != null) {
          //if(response.data.data.includes("stop")){
          //  alert(response.data.data +" 停止充電後，請記得拔出充電槍!")
          //}else{
           if(response.data.code == 200){
            this.$message({showClose: true,message: '綁定成功' ,type: 'success'})
           }else{
            this.$message({showClose: true,message: response.data.message ,type: 'warning'})
           }
           //alert(response.data.code + ":" + response.data.message)
            this.dialogParkingSpaceBinding = false            
            this.getList()
          //}

        //console.log(response);
        //this.getSummaryInfo();

        }
        //this.dialogRemoteControl=false
      }).catch(function (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      //console.log(error.response.status);
      //console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
    console.log(error.config);
  })
    },
    parkingSpaceBindingUpdate() {
      this.updateQuery = {
                dataId      : this.ParkingSpaceData.data_id,
                serviceId   : this.$store.state.currentAccount.serviceInfo.id,
                stationId   : this.ParkingSpaceData.station_id,
                chargePointId : this.ParkingSpaceData.charge_point_id,
                connectorId : this.ParkingSpaceData.connector_id,
                parkingID   : this.listQuery1.parkingID,
            }
       this.targetUrl ="/api/v1/parking_binding/update/binding"
        this.axios.post(this.targetUrl, this.updateQuery ) .then(response => {
        // 在這裡處理回應
        console.log("response",response.data)
        if (response.data.message != null) {
          //if(response.data.data.includes("stop")){
          //  alert(response.data.data +" 停止充電後，請記得拔出充電槍!")
          //}else{
           if(response.data.code == 200){
            this.$message({showClose: true,message: '變更成功' ,type: 'success'})
           }else{
            this.$message({showClose: true,message: response.data.message ,type: 'warning'})
           }
           //alert(response.data.code + ":" + response.data.message)
            this.dialogParkingSpaceBindingUpdate = false            
            this.getList()
          //}

        //console.log(response);
        //this.getSummaryInfo();

        }
        //this.dialogRemoteControl=false
      }).catch(function (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data);
      //console.log(error.response.status);
      //console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }
    console.log(error.config);
  })
    },
    showParkingSpaceBinding() {
      this.dialogParkingSpaceBinding = true
    },
    showParkingSpaceBindingUpdate(row) {
      this.dialogParkingSpaceBindingUpdate = true
      this.ParkingSpaceData = row
      this.parkingspaceOption=null;
      //console.log("row",row)
            this.axios.get("/api/v1/parking_binding/query/avaliable_list?serviceId="+row.service_id+"&stationId="+row.station_id).then(
            res => {
            //console.log("parkingspaceOption:",res.data.data);
            this.parkingspaceOption=res.data.data;
            })
    },
    handleParkingSpaceBindingDelete(index, row) {
      const confirmText = ['確定刪除車位綁定資訊【' + row.description + '】']
      const newDatas = []
      const h = this.$createElement
      for (const i in confirmText) {
        newDatas.push(h('p', null, confirmText[i]))
      }
      this.$confirm('提示', {
        message: h('div', null, newDatas),
        confirmButtonText: '確認',
        cancelButtonText: '取消',
        distinguishCancelAndClose: true,  //close視窗或cancel視窗的時候,可callback cancel跟close
        type: 'warning'
      }).then(() => {
        this.delParkingSpaceBinding(index, row)
      }).catch(
         action => { 
          if(action=='cancel' || action=='close') { 
            this.$message({showClose: true,message: '取消刪除',type: 'success'}) 
          }
      })
    },
    async delParkingSpaceBinding(index, row) {
      let deleteData = { 
        "dataId"          :row.data_id,
      }
      console.table('delete/parking_info [params]:', deleteData);
      this.axios.post('/api/v1/parking_binding/delete/binding', deleteData).then( response => {
        //console.log(response)
        if (response.data.message != null) {
           // alert(response.data.code + ":" + response.data.message)
            if(response.data.code == 200){
              this.$delete(this.list, index);
              this.$message({showClose: true,message: '刪除成功',type: 'success'})
            }else{
              this.$message({showClose: true,message: response.data.message ,type: 'warning'})
           }
        }

      }).catch(err => {
        this.$message({message: '刪除失敗 ('+err.data+')',type: 'error'})
      })
    },
    dialogParkingSpaceSettingOnClose() {
      this.dialogParkingSpaceSettingVisible = false
      this.ParkingSpaceData = undefined
      this.resetPage(this.getList)
    },
  }
}
</script>
